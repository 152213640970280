import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { COOKIE_KEY } from '../constants';
import { CookieType } from '../cookieBanner.model';
import { consentCookieExists } from '../util';

const options: Cookies.CookieAttributes = {
  expires: 365,
  sameSite: 'lax',
  domain:
    process.env.NODE_ENV === 'development'
      ? undefined
      : 'feather-insurance.com',
};

export const setCookieConsent = (cookies: CookieType[]) => {
  Cookies.set(COOKIE_KEY, cookies.join('|'), options);
};

export const useCookieConsent = () => {
  const [hiddenBanner, setHiddenBanner] = useState(true);
  const userHasSetConsent = consentCookieExists();

  useEffect(() => {
    if (!userHasSetConsent) {
      setHiddenBanner(false);
    }
  }, [userHasSetConsent]);

  const cookieConsentSetCookie = (cookies: CookieType[]): void => {
    setCookieConsent(cookies);
    setHiddenBanner(true);
  };

  return { hiddenBanner, setCookie: cookieConsentSetCookie };
};
