/* Generated file. Do not modify. */
import { createElement } from 'react';
import { IconWrapper } from '../IconWrapper';
import type { IconWrapperProps } from '../IconWrapper';
export default (props: IconWrapperProps) =>
  createElement(
    IconWrapper,
    props,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 6h18M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m3 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h14ZM10 11v6M14 11v6"
    />
  );
