export type Consent = 'granted' | 'denied';

export const cookieType = {
  functionality_storage: 'functionality_storage',
  ad_storage: 'ad_storage',
  analytics_storage: 'analytics_storage',
} as const;

export type CookieType = keyof typeof cookieType;

export type ConsentObject = Record<CookieType, Consent>;

export type CookieSelection = Array<CookieType>;

export type CookieBannerProps = {
  onSave: (consent: ConsentObject) => void;
};
