import Cookies from 'js-cookie';

import { COOKIE_KEY } from '../constants';
import {
  Consent,
  ConsentObject,
  CookieType,
  cookieType,
} from '../cookieBanner.model';

const defaultConsent: ConsentObject = Object.freeze({
  [cookieType.functionality_storage]: 'granted',
  [cookieType.ad_storage]: 'denied',
  [cookieType.analytics_storage]: 'denied',
});

export const getConsentFromCookie = (value: CookieType): Consent => {
  if (value === cookieType.functionality_storage) return 'granted';

  const cookieSelection = Cookies.get(COOKIE_KEY);
  const cookieArray =
    typeof cookieSelection === 'string' ? cookieSelection.split('|') : [];

  return cookieArray.includes(value) ? 'granted' : 'denied';
};

export const getCurrentConsent = (): ConsentObject => {
  const defaultConsentCopy = { ...defaultConsent };
  (Object.keys(defaultConsentCopy) as CookieType[]).forEach((cookie) => {
    defaultConsentCopy[cookie] = getConsentFromCookie(cookie);
  });
  return defaultConsentCopy;
};
