import { Button } from '@popsure/dirty-swan';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  CookieBannerProps,
  CookieSelection,
  CookieType,
  cookieType,
} from '../../cookieBanner.model';
import { getCurrentConsent } from '../../hooks/getCurrentConsent';
import { useCookieConsent } from '../../hooks/useCookieConsent';
import linkArrowImage from './assets/link-arrow.svg';
import styles from './CookieBanner.module.scss';

interface Cookie {
  id: CookieType;
  title: string;
  required?: boolean;
  description: string;
}

const cookieList: Array<Cookie> = [
  {
    id: cookieType.functionality_storage,
    title: 'Essential cookies',
    required: true,
    description:
      'These cookies are necessary to provide a functional website to you. They enable features such as allowing you to log in to view your purchased policies, or to be able to pick up where you left off while signing up for insurance. As these cookies are essential to use our website, they cannot be rejected. You can however block or delete them through your browser settings. Be aware that this will impact the functionality of our site.',
  },
  {
    id: cookieType.ad_storage,
    title: 'Marketing cookies',
    description:
      'We use these cookies and pixels to improve our marketing efforts. They allow us to determine, which of our ads work best (through conversion tracking) and which ones we should stop running. Disabling these cookies does not affect your use of our site.',
  },
  {
    id: cookieType.analytics_storage,
    title: 'Performance cookies',
    description:
      'These cookies help us make our website better. They store information about how you use our website, such as the links you click on. All of this data is anonymised and can not be used to identify you. Although important to us, these cookies are non-essential to the use of the site.',
  },
];

export const CookieBanner = (props: CookieBannerProps) => {
  const { onSave } = props;
  const [openBanner, setOpenBanner] = useState(false);
  const [selection, setSelection] = useState<CookieSelection>([
    cookieType.functionality_storage,
    cookieType.ad_storage,
    cookieType.analytics_storage,
  ]);
  const { hiddenBanner, setCookie } = useCookieConsent();

  useEffect(() => {
    if (hiddenBanner) return;

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [hiddenBanner]);

  const handleChange = (e: React.ChangeEvent) => {
    const currentId = e.target.id as CookieType;
    if (selection.indexOf(currentId) === -1) {
      setSelection([...selection, currentId]);
      return;
    }

    setSelection(selection.filter((id) => id !== currentId));
  };

  const handleOpenBanner = () => {
    setSelection([cookieType.functionality_storage]);
    setOpenBanner(true);
  };

  const handleSaveCookies = (e: React.FormEvent) => {
    e.preventDefault();
    setCookie(selection);
    onSave(getCurrentConsent());
  };

  if (hiddenBanner) return null;

  return (
    <div className={`d-flex  ${styles.overlay}`}>
      <div className={`wmx6 w100 ml-auto ta-left ${styles.container}`}>
        <h4 className="p-h4 mb16">Cookies 🍪</h4>
        <div className={styles.overflow}>
          <p className="p-p p-p--small">
            We use 1st and 3rd party cookies and pixels to continuously improve
            our website and your browsing experience. You can choose which
            cookies you would like to accept. Your experience does not change
            depending on this choice, but by allowing us to use cookies you
            directly help us identify issues with our site and improve the
            service we can offer.
          </p>
          <a
            href="https://feather-insurance.com/privacy-policy"
            className="p-a p-p--small d-flex mt8"
          >
            Learn more
            <img
              alt="Open in new window"
              src={linkArrowImage}
              width={16}
              height={18}
            />
          </a>
          {openBanner && (
            <form>
              {cookieList.map((cookie) => (
                <div className="p-label-container" key={cookie.id}>
                  <input
                    id={cookie.id}
                    className="p-checkbox"
                    type="checkbox"
                    disabled={cookie.required}
                    checked={
                      cookie.required ? true : selection.includes(cookie.id)
                    }
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={cookie.id}
                    className={classnames(
                      'p-label p-h4 mt24 mb8',
                      styles.label,
                      {
                        [styles.disabled]: cookie.required,
                      },
                    )}
                  >
                    {cookie.title}
                  </label>
                  <p className="p-p p-p--small ml32">{cookie.description}</p>
                </div>
              ))}
            </form>
          )}
        </div>

        <div className={`mt16 ${styles.footer}`}>
          {openBanner ? (
            <Button
              buttonTitle="Save"
              onClick={handleSaveCookies}
              className="w100"
            />
          ) : (
            <>
              <Button
                buttonTitle="Edit"
                buttonType="secondary"
                onClick={handleOpenBanner}
                className="w100"
              />
              <Button
                buttonTitle="Accept all"
                onClick={handleSaveCookies}
                className="w100"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
